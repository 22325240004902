import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message:string,duration:number,verticalPosition:any,horizontalPosition:any,panelClass:string){
    this._snackBar.open(message, null, {
      duration: duration,
      verticalPosition: verticalPosition, horizontalPosition: horizontalPosition,
      panelClass: [panelClass]
    });
  }
}
