import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-email-form-for-pass-change",
  templateUrl: "./email-form-for-pass-change.component.html",
  styleUrls: ["./email-form-for-pass-change.component.css"],
})
export class EmailFormForPassChangeComponent implements OnInit {
  email: FormControl = new FormControl("", [
    Validators.required,
    Validators.maxLength(50),
  ]);
  submitted = false;
  emailSending = false;
  emailSentSuccess = false;
  emailSentError = false;

  alertMessage: string;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  sendEmail() {
    this.submitted = true;
    if (this.email.errors) return;

    this.emailSending = true;
    this.emailSentError = false;
    this.emailSentSuccess = false;

    this.alertMessage =
      "Το αίτημα σας βρίσκεται σε επεξεργασία. Παρακαλώ περιμένετε";
    this.authService.sendEmailForResetPassword(this.email.value).subscribe(
      (res) => {
        this.alertMessage =
          "Eνα link για να αλλάξετε το κωδικο σας έχει σταλεί στο mail σας";

        this.emailSentSuccess = true;
        this.emailSending = false;
      },
      (error) => {
        this.alertMessage = "Παρουσιάστηκε κάποιο πρόβλημα δοκιμάστε ξανά";
        this.emailSentError = true;
        this.emailSending = false;
        console.log(error.error);
      }
    );
  }
}
