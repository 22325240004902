import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MetadataService } from '../MetadataService';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-administrator-cv-handling',
  templateUrl: './administrator-cv-handling.component.html',
  styleUrls: ['./administrator-cv-handling.component.css']
})
export class AdministratorCvHandlingComponent implements OnInit {

  candidatesData: any;
  candidateData: any;
  fileUrl: string;
  adminData: any;
  message: string;
  handingMultipleCandidates: FormGroup;
  isSelected: boolean = false;
  titleMessage: string;
  bodyMessage: string;
  selectedStatus: string;
  metadataLanguages$: Observable<Array<{ id: number; text: string }>>;
  metadata$: Observable<any>;
  education_levels$: Observable<Array<{ id: number; text: string }>>;
  prefectures$: Observable<Array<{ id: number; text: string }>>;
  candidateSearchResults: any;
  selectedTab: string;
  isAllSelected: boolean = false;

  // for pagination
  currentPage: any = 1;
  pageSize: number = 10;
  firstPage: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private metadataService: MetadataService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.activatedRoute.data
      .subscribe((obj: { data: any }) => {

        this.adminData = obj.data;
        this.authService.isAdmin = true;

        this.activatedRoute.queryParamMap.subscribe(params => {

          this.selectedTab = params.get('tab') ? params.get('tab') : 'pending';
          this.currentPage = params.get('page') ? params.get('page') : this.firstPage;

          this.handingMultipleCandidates =
            this.formBuilder.group({
              status: this.formBuilder.array([])
            });
          this.isAllSelected = false;

          this.getMetadata()
          this.getCandidatesListByStatus();
        });
      });
  }

  getMetadata() {
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.metadataLanguages$ = this.metadata$.pipe(
      map(metadata => metadata.languages.map(language => ({ id: language.id, text: language.name })))
    );

    this.education_levels$ = this.metadata$.pipe(
      map(metadata => metadata.education_levels.map(education_level => ({ id: education_level.id, text: education_level.name })))
    );

    this.prefectures$ = this.metadata$.pipe(
      map(metadata => metadata.prefecture.map(prefecture => ({ id: prefecture.id, text: prefecture.name })))
    );
  }

  candidateProfile(uuid) {
    this.authService.getCandidateProfile(uuid).subscribe((data: any) => {
      this.candidateData = data;

      this.combineMetatadaAndCandidateData();

      $('#candidateDataModal').modal('show');

    });
  }

  combineMetatadaAndCandidateData() {
    this.metadataLanguages$.subscribe((languages: any[]) => {
      this.candidateData.candidate_languages.forEach(item => {
        let x = languages.find(locItem => locItem.id === item.language_id);

        if (x) {
          item.language_name = x.text;
        }
        else {
          item.language_name = "";
        }
      });
    });

    this.education_levels$.subscribe((education_levels: any[]) => {
      //  for the education_lvl_id in cv info
      let x = education_levels.find(education_level => education_level.id === this.candidateData.education_lvl_id);
      if (x) {
        this.candidateData.education_lvl_name = x.text;
      }
      else {
        this.candidateData.education_lvl_name = "";
      }
      //  for the education_lvl_id in education form
      this.candidateData.candidate_education.forEach(item => {
        let x = education_levels.find(locItem => locItem.id === item.education_lvl_id);

        if (x) {
          item.education_lvl_name = x.text;
        }
        else {
          item.education_lvl_name = "";
        }
      });
    });

    this.prefectures$.subscribe((prefectures: any[]) => {
      let x = prefectures.find(prefecture => prefecture.id === this.candidateData.prefecture_id);

      if (x) {
        this.candidateData.prefecture_name = x.text;
      }
      else {
        this.candidateData.prefecture_name = "";
      }
    });
  }

  downLoadCv(candidateUuid) {
    const data = this.authService.downloadFile("candidate", "cv", new Date().getTime().toString(), candidateUuid);
    this.fileUrl = data;
  }

  // update activation status for specific candidate
  onChange(e, uuid) {
    let uuids = [uuid];
    this.authService.updateCandidateStatus(e.target.value, uuids).subscribe(res => {

      var statusMessage = "";
      var panelStyle;
      var trStyle;

      switch (e.target.value) {
        case 'pending':
          statusMessage = "Το προφίλ του υποψηφίου εκκρεμεί";
          panelStyle = 'warning-dialog';
          trStyle = '#fff3cd';
          break;

        case 'approved':
          statusMessage = "Το προφίλ του υποψηφίου εγκρίνεται";
          trStyle = '#dff0d8';
          break;

        case 'disapproved':
          statusMessage = "Το προφίλ του υποψηφίου απορρίπτεται";
          panelStyle = 'error-dialog';
          trStyle = '#f2dede';
          break;
      }

      // change background color for tr
      $(e.target).closest('tr').children('td,th').css('background-color', trStyle);

      this._snackBar.open(statusMessage, null, {
        duration: 5000,
        verticalPosition: 'bottom', horizontalPosition: 'center',
        panelClass: [panelStyle]
      });
    });
  }

  // option's values: pending/approved/disapproved
  getCandidatesListByStatus() {

    this.currentPage = this.currentPage - 1;

    this.authService.getCandidatesListWithFiltersAndSorting('creationDate', 'desc', { status: this.selectedTab }, this.currentPage, this.pageSize).subscribe(candidates => {
      this.candidateSearchResults = candidates.candidateSearchResults;
      this.candidatesData = candidates.candidateSearchResults.content;

      if (this.candidatesData.length === 0) {
        switch (this.selectedTab) {
          case 'pending':
            this.message = "Δεν υπάρχουν εκκρεμείς υποψήφιοι";
            break;

          case 'approved':
            this.message = "Δεν υπάρχουν εγκεκριμένοι υποψήφιοι";
            break;

          case 'disapproved':
            this.message = "Δεν υπάρχουν απορριφθέντες υποψήφιοι";
            break;
        }
      }
    });
  }

  onCheckboxChange(e) {
    const status: FormArray = this.handingMultipleCandidates.get('status') as FormArray;

    if (e.target.checked) {
      status.push(new FormControl(e.target.value));
      this.candidatesData.find(candidate => e.target.value === candidate.uuid).selected = true;
    }

    else {
      let i: number = 0;
      status.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          status.removeAt(i);
          this.candidatesData.find(candidate => item.value === candidate.uuid).selected = false;
          return;
        }
        i++;
      });
    }

    // hide selectAll checkbox if nothing is selected
    if (status.value.length === 0) {
      this.isAllSelected = false;
    }

    // show selectAll checkbox if is all selected
    if (status.value.length === this.candidatesData.length) {
      this.isAllSelected = true;
    }
  }

  onCheckboxChangeSelectAll(e) {
    const status: FormArray = this.handingMultipleCandidates.get('status') as FormArray;
    status.clear();

    // select all
    if (e.target.checked) {
      this.candidatesData.forEach(candidate => {
        candidate.selected = true;
        status.push(new FormControl(candidate.uuid));
        this.isAllSelected = true;
      })
    }
    // deselect all
    else {
      this.candidatesData.forEach(candidate => {
        candidate.selected = false;
      });
      this.isAllSelected = false;
    }
  }

  handlingMultipleProfiles(status) {
    $('#confirmModal').modal('hide');
    this.authService.updateCandidateStatus(status, this.handingMultipleCandidates.value.status).subscribe(res => {
      this.handingMultipleCandidates =
        this.formBuilder.group({
          status: this.formBuilder.array([])
        });
      this.isAllSelected = false;
      this.currentPage = this.currentPage + 1;
      this.getCandidatesListByStatus();
    });
  }

  changeTabOption(selectedtab) {
    this.router.navigate(['/administrator/cvHandling', this.adminData.uuid], { queryParams: { tab: selectedtab, page: this.firstPage }, queryParamsHandling: 'merge' });
  }

  confirmModal(status) {

    const checkStatusForm: FormArray = this.handingMultipleCandidates.get('status') as FormArray;

    if (checkStatusForm.value.length === 0) {
      this._snackBar.open("Δεν έχουν επιλεγεί υποψήφιοι", null, {
        duration: 6000,
        verticalPosition: 'top', horizontalPosition: 'center',
        panelClass: ['warning-dialog']
      });
    }
    else {
      this.selectedStatus = status;
      switch (this.selectedStatus) {

        case 'approved':
          this.titleMessage = "Έγκριση υποψηφίων";
          this.bodyMessage = "Είστε σίγουροι ότι θέλετε να εγκρίνετε τους επιλεγμένους υποψηφίους";
          break;

        case 'disapproved':
          this.titleMessage = "Απόρριψη υποψηφίων";
          this.bodyMessage = "Είστε σίγουροι ότι θέλετε να απορρίψετε τους επιλεγμένους υποψηφίους";
          break;
      }

      $('#confirmModal').modal('show');
    }
  }

  //for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.router.navigate(['/administrator/cvHandling', this.adminData.uuid], { queryParams: { page: this.currentPage }, queryParamsHandling: 'merge' });
  }
}
