import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-administrator-login',
  templateUrl: './administrator-login.component.html',
  styleUrls: ['./administrator-login.component.css']
})
export class AdministratorLoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted = false;
  message: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.isAdmin = true;

    if(this.authService.getAdminUUid()){
      this.router.navigate(['administrator/cvHandling', this.authService.getAdminUUid()]);
    }

    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  login() {
    this.isSubmitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.authService.loginAdmin(this.loginForm.value)
      .subscribe(
        admin => {
          this.router.navigate(['administrator/cvHandling', admin.uuid]);
        }, error => {
          //backend error message 
          this.message = error.error.message;

          if (this.message == "User email doesn't match.") {
            this.message = "Αυτό το usename δεν υπάρχει!"
          }
          if (this.message == "User password doesn't match.") {
            this.message = "Ο κωδικός δεν ταιριάζει με το username!"
          }
        });
        if (this.loginForm) {
          this.loginForm.reset();
          this.loginForm.markAsUntouched();
          this.isSubmitted = false;
          this.message = "";
        }
  }


}
