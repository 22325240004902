import { Component, OnInit } from '@angular/core';
import { env } from 'process';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  epimelitirio: string;
  epimelitiriou: string;
  contactData: string;
  privacymail: string;
  chamberEn: string;
  contactDataEn: string;

  constructor() { }

  ngOnInit() {
    this.epimelitirio = environment.chamber
    this.epimelitiriou = environment.chamber2
    this.contactData = environment.contactData
    this.privacymail = environment.privacymail
    this.chamberEn = environment.chamberEn
    this.contactDataEn = environment.contactDataEn
  }

}
