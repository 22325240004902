import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { PostAJobComponent } from './post-a-job/post-a-job.component';
import { JobListingComponent } from './job-listing/job-listing.component';
import { EditPostJobComponent } from './edit-post-job/edit-post-job.component';
import { AppliedCandidatesListComponent } from './applied-candidates-list/applied-candidates-list.component';


const routes: Routes = [
    { path: "", redirectTo: '/employers', pathMatch: 'full' },
    {
        path: "",
        children: [

            {
                path: "job-listing/:status",
                component: JobListingComponent
            },
            {
                path: "applied/candidates/:uuid/:company_uuid",
                component: AppliedCandidatesListComponent
            },
            {
                path: "job-listing",
                component: JobListingComponent
            },
            {
                path: "complete-profile",
                component: CompleteProfileComponent
            },
            {
                path: "post-a-job",
                component: PostAJobComponent
            },
            {
                path: "edit-post-job/:uuid",
                component: EditPostJobComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmployersRoutingModule {
}
