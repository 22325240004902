import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { throwError, Observable } from 'rxjs';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { Router } from '@angular/router';
import { BaseService } from '../BaseService';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService:AuthService,
              private router:Router){}

  /*
    Intercepts and adds token if exists
    if token exists public urls request get also an auth header even if is not required/thats not a problem to get the response
  */

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    
    console.log("Auth interceptor : " + req.url)
    const token = this.authService.getToken();

    //Second condition: admin login url skip interceptor when a candidates's or company's token is already in localstorage
    if(token && !(req.url === `${environment.apiEndPoint}/login/admin`)){
      
        const refreshToken = this.authService.getRefreshToken();
        const refreshTokenDecoded = this.authService.decodeToken(refreshToken);

        if(refreshTokenDecoded.exp > new Date().getTime()/1000){
          const authReq = this.authService.addAuthHeadersToRequest(req,token);
          return next.handle(authReq);
        }
        // logout if refresh token is expired - doesnt matter if req needs or not authentication
        else{
          console.log("expired refresh token")
          this.logoutActions();
        }
    }
    //update authenticated subject value if it changed in another tab(logout in another tab in browser)
    else{
      console.log("not token");
      this.authService.authenticated.next(false);
    }

    return next.handle(req);
  }

  logoutActions(){
    if(this.authService.isCompany() || this.authService.isCandidate()){
      this.authService.AfterUnauthorizedRequestActions()
    }
    else if(this.authService.isAdminFunc()){
        this.authService.AfterAdminUnauthorizedRequestActions()
    }
    this.authService.AfterUnAuthorizedRequestUiMessage()
  }


}
