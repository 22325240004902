import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/BaseService';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    metadata: any;

    constructor(
        private authService: AuthService

    ) { }

    init(): Observable<any> {
        return this.getMetaData();
    }

    getMetaData(): Observable<any[]> {
       
        if (this.metadata) {     
            return of(this.metadata);
        }
        else {
           
            return this.authService.getMetaData(this.metadata)
                .pipe(map(
                    data => {
                       
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        this.metadata = data;               
                        return this.metadata;
                    }));
        }
    }


}