import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MustMatch } from 'src/helpers/must-match.validator';
import { MatSnackBar } from '@angular/material';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-administrator-create-mediators',
  templateUrl: './administrator-create-mediators.component.html',
  styleUrls: ['./administrator-create-mediators.component.css']
})
export class AdministratorCreateMediatorsComponent implements OnInit {

  createAdminForm: FormGroup;
  isSubmitted: boolean = false;
  message: string = "";
  adminUuid: any;
  admin: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.adminUuid = params.uuid ? params.uuid : this.authService.getUUID();
      this.authService.getAdminProfile(this.adminUuid).subscribe(admin => {

        this.admin = admin
        this.authService.isAdmin = true;

        this.createAdminForm =
          this.formBuilder.group({
            username: new FormControl("", [Validators.required, Validators.maxLength(50), Validators.minLength(8)]),
            email: new FormControl("", [Validators.required, Validators.email]),
            password: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(255)]),
            confirmPassord: new FormControl("", [Validators.required]),
            firstname: new FormControl("", [Validators.required, Validators.maxLength(50)]),
            lastname: new FormControl("", [Validators.required, Validators.maxLength(50)]),
            role: new FormControl("administrator", [Validators.required])
          }, {
            validator: MustMatch('password', 'confirmPassord')
          });
      })
    });

  }

  get formControls() {
    return this.createAdminForm.controls;
  }

  createAdmin() {

    $("#createAdminConfirmModal").modal("hide");
    
    this.authService.createMediatorAdmin(this.createAdminForm.value).subscribe(mediator => {
      this.openSnackBar();
    }, error => {
      //backend error message 
      this.message = error.error.message;

      if (this.message == "Username already exists.") {
        this.message = "Υπάρχει ήδη διαχειριστής με αυτό το όνομα χρήστη."
      }
    });
    if (this.createAdminForm) {
      this.createAdminForm.reset();
      this.createAdminForm.markAsUntouched();
      this.isSubmitted = false;
      this.message = "";
    }

  }

  // pop up success message 
  openSnackBar() {
    this._snackBar.open("Επιτυχής καταχώρηση διαχειριστή", null, {
      duration: 6000,
    });
  }

  openConfirmModal(){
    this.isSubmitted = true;
    if (this.createAdminForm.invalid) {
      return;
    }
    else{
    $("#createAdminConfirmModal").modal("show");
  }
}

}
