import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  
  private messageForLoginAndRegisterModal:string;
  private messageForSignUpPromo:string =`Κάντε αίτηση στη δουλειά που σας ταιριάζει μέσα από εκατοντάδες αγγελίες, 
                                          βρείτε ικανούς και έμπιστους συνεργάτες για την επιχείρηση σας.
                                          Συνδεθείτε ή κάντε εγγραφή σαν εργαζόμενος ή επιχείρηση για να έχετε πρόσβαση
                                          σε όλες τις υπηρεσίες που σας αφορούν.
                                          `;
  constructor() { }

  setMessageForLoginAndRegisterModal(message:string):void{
  	  this.messageForLoginAndRegisterModal = message;
  }
  
  getMessageForLoginAndRegisterModal():string{
  	return this.messageForLoginAndRegisterModal;
  }

  setMessageForSignUpPromo(message:string):void{
    this.messageForLoginAndRegisterModal = message;
  }
  getMessageForSignUpPromo():string{
      return this.messageForSignUpPromo;
  }
}
