import {HTTP_INTERCEPTORS} from '@angular/common/http';

import { AuthInterceptorService } from './auth-interceptor.service';
import { RefreshTokenInterceptorService } from './refresh-token-interceptor.service';


export const interceptorProviders = 
   [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptorService, multi: true}
   ];