import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MustMatch } from 'src/helpers/must-match.validator';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit {

  resetPassToken;
  password:FormControl;
  confirmPassword:FormControl;
  resetPasswordForm: FormGroup;
  submitted = false;
  alertMessage:string;
  passwordSent = false;
  passwordSentError = false;
  passwordSentSuccess = false;
  passwordSentProcess = false;
  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private authService:AuthService) { }

  ngOnInit() {
    this.resetPassToken = this.route.snapshot.queryParamMap.get("resetPasswordToken");

    this.resetPasswordForm = this.formBuilder.group({
      password : new FormControl('',[Validators.required, Validators.minLength(6), Validators.maxLength(255)]),
      confirmPassword : new FormControl('',[Validators.required])
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });

  }

  sendNewPassword(){
    this.submitted = true;
    if(this.resetPasswordForm.invalid) return;

    this.passwordSentProcess = true;
    this.passwordSentSuccess = false;
    this.passwordSentError = false;

    let decodedResetPasswordToken = this.authService.decodeToken(this.resetPassToken);
    let candidateUuid = decodedResetPasswordToken.uuid;
    this.authService.sendNewPassword(this.resetPassToken,candidateUuid,this.resetPasswordForm.controls.password.value,this.resetPasswordForm.controls.confirmPassword.value)
    .subscribe(res=>{
  
        this.alertMessage = 'O κωδικός αλλάξε με επιτυχία'
        this.passwordSent = true;
        this.passwordSentSuccess = true;
        this.passwordSentProcess = false;
    },
    error =>{
      console.log(error);
      this.alertMessage = 'Παρουσιάστηκε κάποιο σφάλμα κατά την επεξεργασία του αιτήματός σας'
      this.passwordSent = true;
      this.passwordSentError = true;
      this.passwordSentProcess = false;
    })


  }



}
