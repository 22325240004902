import { Directive, ElementRef } from '@angular/core';

declare const $: any;

@Directive({
  selector: '[rdNavbar]'
})
export class RdNavbarDirective {

  constructor(private readonly element: ElementRef) {
    this.attach();
  }

  private attach() {
    const isNoviBuilder = false;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var aliaces, i, j, len, value, values, responsiveNavbar;

    aliaces = ["-", "-sm-", "-md-", "-lg-", "-xl-", "-xxl-"];
    values = [0, 576, 768, 992, 1200, 1600];
    responsiveNavbar = {};

    for (i = j = 0, len = values.length; j < len; i = ++j) {
      value = values[i];
      if (!responsiveNavbar[values[i]]) {
        responsiveNavbar[values[i]] = {};
      }
      if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'layout')) {
        responsiveNavbar[values[i]].layout = $(this.element.nativeElement).attr('data' + aliaces[i] + 'layout');
      }
      if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'device-layout')) {
        responsiveNavbar[values[i]]['deviceLayout'] = $(this.element.nativeElement).attr('data' + aliaces[i] + 'device-layout');
      }
      if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'hover-on')) {
        responsiveNavbar[values[i]]['focusOnHover'] = $(this.element.nativeElement).attr('data' + aliaces[i] + 'hover-on') === 'true';
      }
      if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'auto-height')) {
        responsiveNavbar[values[i]]['autoHeight'] = $(this.element.nativeElement).attr('data' + aliaces[i] + 'auto-height') === 'true';
      }

      if (isNoviBuilder) {
        responsiveNavbar[values[i]]['stickUp'] = false;
      } else if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'stick-up')) {
        responsiveNavbar[values[i]]['stickUp'] = $(this.element.nativeElement).attr('data' + aliaces[i] + 'stick-up') === 'true';
      }

      if ($(this.element.nativeElement).attr('data' + aliaces[i] + 'stick-up-offset')) {
        responsiveNavbar[values[i]]['stickUpOffset'] = $(this.element.nativeElement).attr('data' + aliaces[i] + 'stick-up-offset');
      }
    }

    $(this.element.nativeElement).RDNavbar({
      anchorNav: !isNoviBuilder,
      stickUpClone: ($(this.element.nativeElement).attr("data-stick-up-clone") && !isNoviBuilder) ? $(this.element.nativeElement).attr("data-stick-up-clone") === 'true' : false,
      responsive: responsiveNavbar,
      callbacks: {
        onStuck: function () {
          var navbarSearch = this.$element.find('.rd-search input');

          if (navbarSearch) {
            navbarSearch.val('').trigger('propertychange');
          }
        },
        onDropdownOver: function () {
          return !isNoviBuilder;
        },
        onUnstuck: function () {
          if (this.$clone === null)
            return;

          var navbarSearch = this.$clone.find('.rd-search input');

          if (navbarSearch) {
            navbarSearch.val('').trigger('propertychange');
            navbarSearch.trigger('blur');
          }

        },
        onToggleSwitch: function () {
          if (isSafari && $(this).hasClass('rd-navbar-popup-toggle')) {
            const page: HTMLElement = document.querySelector('.page');
            page.style.position = 'fixed';
            page.style.overflow = 'hidden';
            page.style.width = '100%';
            page.style.height = '100%';
            // alert('switch'); 
          }
        },
        onToggleClose: function () {
          if (isSafari && $(this).hasClass('rd-navbar-popup-toggle')) {
            const page: HTMLElement = document.querySelector('.page');
            page.style.position = 'relative';
            page.style.overflow = 'hidden';
            page.style.width = '100%';
            page.style.height = 'auto';
            // alert('close');
          }
        }
      }
    });

    if ($(this.element.nativeElement).attr("data-body-class")) {
      document.body.className += ' ' + $(this.element.nativeElement).attr("data-body-class");
    }
  }

}
