import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertMessageComponent } from '../messages-helpers/alert-message/alert-message.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    AlertMessageComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [AlertMessageComponent, ConfirmModalComponent]
})
export class AlertMessagesModule { }
