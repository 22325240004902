import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { AuthService } from "../auth.service";
import { RouterLink, Router, ActivatedRoute } from "@angular/router";
import { MetadataService } from "../MetadataService";
import { map, shareReplay } from "rxjs/operators";

@Component({
  selector: "app-job-listing",
  templateUrl: "./job-listing.component.html",
  styleUrls: ["./job-listing.component.css"],
})
export class JobListingComponent implements OnInit {
  //Init
  getDataInit = true;
  isSigned: Observable<boolean>;

  metadata$: Observable<any>;
  locations$: Observable<Array<{ id: number | string; text: string }>>;
  employment_types$: Observable<Array<{ id: number; name: string }>>;
  types: any[];
  industries$: Observable<Array<{ id: number; name: string }>>;
  industries: any[];

  updatedate$: any;
  expirationdate$: any;

  searchByFilterForm: FormGroup;
  allJobs: any;
  items: Array<any>;
  totalElements: any;

  filters: any;

  isFiltered: boolean = false;

  // bookmarkable filters
  employmentTypeId: any;
  industryId: string;
  salary: string;
  createdAfterCheck: string;
  locationId: any;
  keywords: any;

  salaryFrom: string;
  salaryTo: string;
  selectedIndustry: any = null;

  // for pagination
  currentPage: any;
  pageSize: number = 8;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private metadataService: MetadataService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.salary = params.get("salary");
      this.locationId = params.get("locationId");
      this.keywords = params.get("keywords");
      this.createdAfterCheck = params.get("datePicker");
      this.industryId = params.get("industryId");
      this.employmentTypeId = params.getAll("employmentTypeId");
      this.currentPage = params.get("page") ? params.get("page") : 1;

      this.authService.isAuthenticated$.subscribe((value: any) => {
        this.isSigned = value;
      });

      this.searchByFilterForm = this.formBuilder.group({
        employmentTypeId: this.formBuilder.array([]),
        industryId: new FormControl(this.industryId ? this.industryId : "", []),
        salaryFrom: new FormControl(this.salaryFrom ? this.salaryFrom : "", []),
        salaryTo: new FormControl(this.salaryTo ? this.salaryTo : "", []),
        salary: new FormControl(this.salary ? this.salary : "", []),
        createdAfter: new FormControl("", []),
        createdAfterCheck: new FormControl(
          this.createdAfterCheck ? this.createdAfterCheck : "",
          []
        ),
        locationId: new FormControl(
          this.locationId ? this.locationId : null,
          []
        ),
        keywords: new FormControl(this.keywords ? this.keywords : null, []),
      });

      // createdAfter, salaryFrom, salaryTo used to filter the data in backend
      // salary, createdAfterCheck used for passing the value to radio buttons

      // set the value for createdAfter
      if (this.createdAfterCheck) {
        this.getcreatedAfterDate(this.createdAfterCheck, this.currentPage);
      }

      // set the values for salaryFrom and salaryTo
      if (this.salary) {
        this.getSalary(this.salary, this.currentPage);
      }

      if (this.employmentTypeId) {
        const employmentTypeId: FormArray = this.searchByFilterForm.get(
          "employmentTypeId"
        ) as FormArray;
        this.employmentTypeId.forEach((value) => {
          employmentTypeId.push(new FormControl(value));
        });
      }

      const locationId = this.searchByFilterForm.get("locationId");
      locationId.valueChanges.subscribe((val) => {
        if (val == -1) {
          locationId.setValue("");
        }
      });

      this.getPostJobsData();
    });
  }

  get formSearchFiltersControls() {
    return this.searchByFilterForm.controls;
  }

  getPostJobsData() {
    if (
      this.searchByFilterForm.value.employmentTypeId.length > 0 ||
      this.searchByFilterForm.value.salary ||
      this.searchByFilterForm.value.createdAfterCheck ||
      this.searchByFilterForm.value.industryId
    ) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }

    this.getPostJobsList();
  }

  getPostJobsList() {
    if (this.searchByFilterForm.invalid) {
      return;
    }

    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });

    this.currentPage = this.currentPage - 1;

    this.authService
      .jobPostsList(
        this.searchByFilterForm.value,
        this.currentPage,
        this.pageSize
      )
      .subscribe(
        (joblist) => {
          this.filters = joblist.jobPostFilters;
          this.allJobs = joblist.jobPostSearchResults;
          this.items = this.allJobs.content;
          this.totalElements = this.allJobs.totalElements;

          this.getMetadata();
          this.getData();
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  getMetadata() {
    this.metadata$ = this.metadataService.getMetaData().pipe(shareReplay(1));

    this.locations$ = this.metadata$.pipe(
      map((metadata) =>
        [{ id: -1, text: "Ολες οι Περιοχές" }].concat(
          metadata.locations.map((location) => ({
            id: location.id,
            text: location.name,
          }))
        )
      )
    );

    this.employment_types$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.employment_types.map((employment_type) => ({
          id: employment_type.id,
          text: employment_type.name,
        }))
      )
    );

    this.industries$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.industries.map((industry) => ({
          id: industry.id,
          text: industry.name,
        }))
      )
    );
  }

  getData() {
    //compares locationId from alljobs and locationId from metadata
    //finds the location name and stores it in alljobs content
    this.locations$.subscribe((locations: any[]) => {
      this.allJobs.content.forEach((item) => {
        let x = locations.find((locItem) => locItem.id === item.location_id);

        if (x) {
          item.location_name = x.text;
        } else {
          item.location_name = "-";
        }
      });
    });

    this.industries$.subscribe((industry: any[]) => {
      this.industries = industry;
    });

    this.employment_types$.subscribe((employment_types: any[]) => {
      this.types = employment_types;
      if (this.employmentTypeId) {
        // selected is used in checkbox
        this.types.forEach((type) => {
          let x = this.employmentTypeId.find(
            (param) => param === type.id.toString()
          );
          if (x) {
            type.selected = true;
          } else {
            type.selected = false;
          }
        });
      }

      this.allJobs.content.forEach((item) => {
        let x = employment_types.find(
          (locItem) => locItem.id === item.employment_type_id
        );

        if (x) {
          item.employment_types_name = x.text;
        } else {
          item.employment_types_name = "Μη διαθέσιμο";
        }
      });
    });

    this.allJobs.content.forEach((item) => {
      //get company logo for every item on job list
      if (item.company_logoId) {
        item.logo = this.authService.getLogo(
          "company",
          "logo",
          item.id,
          new Date().getTime().toString()
        );
      }
      this.updatedate$ = item.creation_date;
      this.expirationdate$ = item.expiration_date;
    });
  }

  getJobListByKeyWordLocation() {
    if (this.searchByFilterForm.invalid) {
      console.log("invalid post a job form");
      return;
    }
    this.router.navigate(["/job-listing"], {
      queryParams: {
        keywords: this.searchByFilterForm.value.keywords,
        locationId: this.searchByFilterForm.value.locationId,
        page: 1,
      },
      queryParamsHandling: "merge",
    });
  }

  jobDetails(uuid: any) {
    this.router.navigate(["job-details", uuid]);
  }

  //for showing the tags of a specific industry
  selectedIndustryHandler(event: any) {
    //update the ui
    //selectedIndustry holds the selected industry id
    this.selectedIndustry = event;
    this.searchByFilterForm.patchValue({ industryId: this.selectedIndustry });

    this.router.navigate(["/job-listing"], {
      queryParams: { industryId: this.selectedIndustry, page: 1 },
      queryParamsHandling: "merge",
    });
  }

  //for employment checkbox
  onCheckboxChange(e) {
    const employmentTypeId: FormArray = this.searchByFilterForm.get(
      "employmentTypeId"
    ) as FormArray;
    if (e.target.checked) {
      employmentTypeId.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      employmentTypeId.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          employmentTypeId.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.router.navigate(["/job-listing"], {
      queryParams: {
        employmentTypeId: this.searchByFilterForm.value.employmentTypeId,
        page: 1,
      },
      queryParamsHandling: "merge",
    });
  }

  //for salary radio filter
  getSalary(salary, currentPage?) {
    if (!currentPage) {
      this.currentPage = 1;
    }

    const salaryFrom: FormControl = this.searchByFilterForm.get(
      "salaryFrom"
    ) as FormControl;
    const salaryTo: FormControl = this.searchByFilterForm.get(
      "salaryTo"
    ) as FormControl;
    if (salary === "1") {
      salaryFrom.setValue(0);
      salaryTo.setValue(500);
    } else if (salary === "2") {
      salaryFrom.setValue(500);
      salaryTo.setValue(1000);
    } else if (salary === "3") {
      salaryFrom.setValue(1000);
      salaryTo.setValue(1500);
    } else if (salary === "4") {
      salaryFrom.setValue(1500);
      salaryTo.setValue(2000);
    } else if (salary === "5") {
      salaryFrom.setValue(2000);
    }
    this.router.navigate(["/job-listing"], {
      queryParams: { salary: this.searchByFilterForm.value.salary, page: 1 },
      queryParamsHandling: "merge",
    });
  }

  //for date post radio filter
  getcreatedAfterDate(datePicker, currentPage?) {
    if (!currentPage) {
      this.currentPage = 1;
    }
    const myDate = new Date();
    myDate.setDate(myDate.getDate() - datePicker);
    const splitted = myDate.toISOString().split("T");
    const createdAfter: FormControl = this.searchByFilterForm.get(
      "createdAfter"
    ) as FormControl;
    createdAfter.setValue(splitted[0]);

    this.router.navigate(["/job-listing"], {
      queryParams: {
        datePicker: this.searchByFilterForm.value.createdAfterCheck,
        page: this.currentPage,
      },
      queryParamsHandling: "merge",
    });
  }

  discardfilters() {
    this.router.navigate(["/job-listing"], {
      queryParams: {
        locationId: this.searchByFilterForm.value.locationId,
        keywords: this.searchByFilterForm.value.keywords,
        page: 1,
      },
    });
  }

  //used for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.router.navigate(["/job-listing"], {
      queryParams: { page: this.currentPage },
      queryParamsHandling: "merge",
    });
  }
}
