import { Component, OnInit } from '@angular/core';
import { DataService } from '../dataService';
import { SocialAuthService } from '../social-auth.service';
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {

  constructor(
    private socialAuth: SocialAuthService,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }

  // signInWithSocial(context) {

  //   if (this.dataService.socialMedia === "LinkedIn") {
  //     localStorage.setItem("context", context);
  //     window.location.href = 'https://www.linkedin.com/oauth/v2/authorization?client_id=77nu2dnpcj86v0&response_type=code&redirect_uri=http://localhost:4200/login&scope=r_liteprofile r_emailaddress';
  //   }
  //   else {
  //     this.socialAuth.signInWithSocial();
  //   }
  // }

}
