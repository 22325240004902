import { Component, OnInit } from '@angular/core';
import { MessageService } from '../message.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-promo-sign-up',
  templateUrl: './promo-sign-up.component.html',
  styleUrls: ['./promo-sign-up.component.css']
})
export class PromoSignUpComponent implements OnInit {

  isSigned: any;

  constructor(private messageService: MessageService,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((value: any) => {
      this.isSigned = value;
    });
  }

    // modaOptions LoginModal/SignUpModal
    openSignUpOrLoginModal(modaloption){

      // reset form 
      $(modaloption).on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset');
      });
  
      // hide error message
      $(modaloption).on('hidden.bs.modal', function () {
        $(".modal-body #errorMessage").hide();
      });
  
      // shows modal
      $(modaloption).modal("show");
    }

}
