import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationBsComponent } from './pagination-bs.component';

@NgModule({
  declarations: [PaginationBsComponent],
  imports: [
    CommonModule
  ],
  exports: [PaginationBsComponent]
})
export class PaginationBsModule { }
