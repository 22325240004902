import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MetadataService } from '../MetadataService';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-administrator-job-post-handling',
  templateUrl: './administrator-job-post-handling.component.html',
  styleUrls: ['./administrator-job-post-handling.component.css']
})
export class AdministratorJobPostHandlingComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private metadataService: MetadataService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }


  jobPostsData: any;
  selectedTab: string;
  jobPost: any;
  locations$: Observable<Array<{ id: number; text: string; }>>;
  metadata$: Observable<any>;
  jobLocation$: Observable<{ id: number; text: string; }>;
  handingMultipleJobPosts: FormGroup;
  selectedStatus: string;
  titleMessage: string;
  bodyMessage: string;
  isSelected: boolean = false;
  message: string;
  jobPosts: any;
  isAllSelected: boolean = false;

  // for pagination
  currentPage: any = 1;
  pageSize: number = 10;
  firstPage: number = 1;

  ngOnInit() {

    this.handingMultipleJobPosts =
      this.formBuilder.group({
        status: this.formBuilder.array([])
      });

    this.activatedRoute.queryParamMap.subscribe(params => {

      this.selectedTab = params.get('tab') ? params.get('tab') : 'pending';
      this.currentPage = params.get('page') ? params.get('page') : this.firstPage;

      this.handingMultipleJobPosts =
        this.formBuilder.group({
          status: this.formBuilder.array([])
        });
      this.isAllSelected = false;
      this.getJobPosts();
    });

  }

  changeTabOption(selectedtab) {
    this.router.navigate(['/administrator/jobPostHandling'], { queryParams: { tab: selectedtab, page: this.firstPage }, queryParamsHandling: 'merge' });
  }

  getJobPosts() {
    this.currentPage = this.currentPage > 0 ? this.currentPage- 1 : 0;
    this.authService.getActiveJobPostsByActivationStatus(this.selectedTab, this.currentPage, this.pageSize).subscribe(data => {

      this.jobPosts = data;
      this.jobPostsData = data.content;

      if (this.jobPostsData.length === 0) {
        switch (this.selectedTab) {
          case 'pending':
            this.message = "Δεν υπάρχουν νέες θέσεις εργασίας";
            break;

          case 'approved':
            this.message = "Δεν υπάρχουν εγκεκριμένες θέσεις εργασίας";
            break;

          case 'disapproved':
            this.message = "Δεν υπάρχουν απορριφθέντες θέσεις εργασίας";
            break;
        }
      }
    })
  }

  onChange(e, uuid) {

    let uuids = [uuid];
    this.authService.updateJobPostActivationStatus(e.target.value, uuids).subscribe(res => {
      var statusMessage = "";
      var panelStyle;
      var trStyle;

      switch (e.target.value) {
        case 'pending':
          statusMessage = "Το προφίλ του υποψηφίου εκκρεμεί";
          panelStyle = 'warning-dialog';
          trStyle = '#fff3cd';
          break;

        case 'approved':
          statusMessage = "Το προφίλ του υποψηφίου εγκρίνεται";
          trStyle = '#dff0d8';
          break;

        case 'disapproved':
          statusMessage = "Το προφίλ του υποψηφίου απορρίπτεται";
          panelStyle = 'error-dialog';
          trStyle = '#f2dede';
          break;
      }

      // change background color for tr
      $(e.target).closest('tr').children('td,th').css('background-color', trStyle);

      this._snackBar.open(statusMessage, null, {
        duration: 5000,
        verticalPosition: 'bottom', horizontalPosition: 'center',
        panelClass: [panelStyle]
      });
    });
  }

  jobPostDetails(uuid) {

    this.authService.getJobDetailsByuuid(uuid).subscribe(data => {
      this.jobPost = data;
      this.jobPost.benefits = this.jobPost.benefits.replace('<ul>', '<ul id="benefitsList" class="list-marked-3">');
      this.jobPost.prerequisites = this.jobPost.prerequisites.replace('<ul>', '<ul id="prerequisitesList" class="list-marked-3">');

      if (this.jobPost.company_logoId) {
        this.jobPost.logo = this.authService.getLogo("company", "logo", this.jobPost.company_uuid, new Date().getTime().toString());
      }

      this.getMetadata();
      this.compineJobPostDataAndMetadata();

      $('#jobPostDetailsModal').modal('show');
    })
  }

  getMetadata() {
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.locations$ = this.metadata$.pipe(
      map(
        metadata => metadata.locations.map(location => ({ id: location.id, text: location.name }))
      ))
  }

  compineJobPostDataAndMetadata() {
    this.jobLocation$ = this.metadata$.pipe(
      map(
        metadata => metadata.locations.map(location => ({ id: location.id, text: location.name })).find(location => location.id === this.jobPost.location_id)
      ))
    this.jobLocation$.subscribe((location) => {
      this.jobPost.location_name = location.text;
    })
  }

  confirmModal(status) {

    const checkStatusForm: FormArray = this.handingMultipleJobPosts.get('status') as FormArray;

    if (checkStatusForm.value.length === 0) {
      this._snackBar.open("Δεν έχουν επιλεγεί θέσεις εργασίας", null, {
        duration: 6000,
        verticalPosition: 'top', horizontalPosition: 'center',
        panelClass: ['warning-dialog']
      });
    }
    else {
      this.selectedStatus = status;
      switch (this.selectedStatus) {

        case 'approved':
          this.titleMessage = "Έγκριση θέσεων εργασίας";
          this.bodyMessage = "Είστε σίγουροι ότι θέλετε να εγκρίνετε τις επιλεγμένες θέσεις εργασίας";
          break;

        case 'disapproved':
          this.titleMessage = "Απόρριψη θέσεων εργασίας";
          this.bodyMessage = "Είστε σίγουροι ότι θέλετε να απορρίψετε τις επιλεγμένες θέσεις εργασίας";
          break;
      }

      $('#confirmModal').modal('show');
    }
  }

  onCheckboxChange(e) {
    const status: FormArray = this.handingMultipleJobPosts.get('status') as FormArray;

    if (e.target.checked) {
      status.push(new FormControl(e.target.value));
      this.jobPostsData.find(jobPost => e.target.value === jobPost.uuid).selected = true;
    }
    else {
      let i: number = 0;
      status.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          status.removeAt(i);
          this.jobPostsData.find(jobPost => item.value === jobPost.uuid).selected = false;
          return;
        }
        i++;
      });
    }
    // hide selectAll checkbox if nothing is selected
    if (status.value.length === 0) {
      this.isAllSelected = false;
    }

    // show selectAll checkbox if is all selected
    if (status.value.length === this.jobPostsData.length) {
      this.isAllSelected = true;
    }
  }

  onCheckboxChangeSelectAll(e) {

    const status: FormArray = this.handingMultipleJobPosts.get('status') as FormArray;
    status.clear();

    // select all
    if (e.target.checked) {

      this.jobPostsData.forEach(jobPost => {
        jobPost.selected = true;
        status.push(new FormControl(jobPost.uuid));
        this.isAllSelected = true;
      });
    }
    // deselect all
    else {
      this.jobPostsData.forEach(candidate => {
        candidate.selected = false;
      });
      this.isAllSelected = false;
    }
  }


  handlingMultipleJobPosts(status) {
    this.authService.updateJobPostActivationStatus(status, this.handingMultipleJobPosts.value.status).subscribe(res => {

      $('#confirmModal').modal('hide');

      this.handingMultipleJobPosts =
        this.formBuilder.group({
          status: this.formBuilder.array([])
        });
      this.isAllSelected = false;
      this.currentPage = this.currentPage + 1;
      this.getJobPosts();
    });
  }

  //for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.router.navigate(['/administrator/jobPostHandling'], { queryParams: { page: this.currentPage }, queryParamsHandling: 'merge' });
  }

}

