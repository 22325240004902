import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService{
  //Bytes in 1MB (used for file size validation)
  public readonly ONE_MB: number = 1048576;

  //Permitted types for images
  public readonly JPEG: string = "image/jpeg"; 
  public readonly PNG: string = "image/png"; 
  public readonly GIF: string = "image/gif";
  public readonly BMP: string = "image/bmp";

  //Permitted types for files
  public readonly PDF: string = "application/pdf"; 
  public readonly DOC: string = "application/msword"; 
  public readonly DOCX: string = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; 

  //Regex for validators
  public readonly URL_REGEX: RegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/; //taken from https://urlregex.com/
  public readonly POSITIVE_OR_ZERO_REGEX: string = '^[0-9]*$'; 
  public readonly POSITIVE_NOT_ZERO_REGEX: string = '^[1-9][0-9]*$'; 
}