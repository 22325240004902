import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  constructor() { }

  @Input() bodyMessage: string;
  @Input() titleMessage: string;

  @Output() confirm = new EventEmitter<any>(true);

  ngOnInit() {
  }

  // call confirmModal function in parent component
  confirmed() {
    this.confirm.emit(true);
  }

}
