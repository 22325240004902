import { Injectable } from "@angular/core";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { AuthService as AngularSocialService } from "angularx-social-login";
import { DataService } from "./dataService";
import { AuthService } from "./auth.service";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SocialAuthService {
  constructor(
    private angularSocialService: AngularSocialService,
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  signInWithSocial(provider): void {
    let socialPlatformProvider =
      provider === "Google"
        ? GoogleLoginProvider.PROVIDER_ID
        : FacebookLoginProvider.PROVIDER_ID;

    this.angularSocialService
      .signIn(socialPlatformProvider)
      .then((userData) => {
        this.socialLogin(userData);
      });
  }

  // needs refactor
  // do the check in the back
  // check if the user has already an account
  // if the user has one then sign in else sign up
  socialLogin(signInData) {
    signInData.social_login = signInData.provider;
    signInData.password = signInData.id;
    signInData.firstname = signInData.firstName;
    signInData.lastname = signInData.lastName;

    this.authService.signupCandidate(signInData).subscribe(
      (userdata) => {
        this.authService.isValidCandidate = userdata.isValid;
        this.router.navigate(["candidates/complete-profile"]);
      },
      (error) => {
        signInData.socialLogin = signInData.provider;
        signInData.context = "candidate";

        this.authService.sociallogin(signInData).subscribe(
          (data) => {
            this.authService.isValidCandidate = data.isValid;

            if (!this.authService.isValidCandidate) {
              this.router.navigate(["candidates/complete-profile"]);
            }
          },
          (error) => {
            this._snackBar.open(
              "Aνεπιτυχής σύνδεση ως υποψήφιος. Υπάρχει ήδη λογαριασμός μέσω " +
                signInData.socialLogin +
                " ως επιχείρηση με αυτά τα στοιχεία",
              null,
              {
                duration: 9000,
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["error-dialog"],
              }
            );
          }
        );
      }
    );
  }
}
