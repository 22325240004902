import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { take, map, catchError } from "rxjs/operators";
import { AllJobsService } from "../AllJobsSevice";
import { AuthService } from "src/app/auth.service";

@Injectable()
export class ProfileResolver implements Resolve<any> {
  companyid: any;

  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.companyid = route.params.id
      ? route.params.id
      : this.authService.getCompanyId();

    return this.authService.getCompanyInfo(this.companyid).pipe(
      take(1),
      catchError((error) => {
        return throwError(error);
      }),
      map((resp: any) => {
        if (resp) {
          return resp;
        } else {
          return [];
        }
      })
    );
  }
}
