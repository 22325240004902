import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/BaseService';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AllJobsService extends BaseService {

    alljobs: any;
    locationTitleFilter: any;
    relatedJobs: any;
    count: number;
    constructor(
        private authService: AuthService,
        private http: HttpClient
    ) { super() }

    // init(): Observable<any> {
    //     return this.getAlljobs();
    // }

    // get jobs by industry and tag
    getRelatedJobs(industryId: any, currentPage: number, pageSize:number): Observable<any[]> {
            return this.authService.getRelatedJobs(this.relatedJobs, industryId, currentPage, pageSize)
                .pipe(map(
                    data => {
                        this.relatedJobs = data;
                        return this.relatedJobs;
                    }
                ));
    }

    getJobsCount(): Observable<any> {
        return this.authService.getJobPostsCount().pipe(map(
            number => {
                this.count = number;
                return this.count;
            }
        ));
    }

}