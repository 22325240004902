import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import paginate from 'jw-paginate';
import { min } from 'rxjs/operators';

@Component({
  selector: 'app-pagination-bs',
  templateUrl: './pagination-bs.component.html',
  styleUrls: ['./pagination-bs.component.css']
})
export class PaginationBsComponent implements OnInit {

  constructor() { }

  @Input() items;
  // for bookmarkable components
  // initialPage gets the value from params
  @Input() initialPage: number = 1;
  @Input() pageSize: number;

  @Output() changePage = new EventEmitter<any>(true);

  maxPages: number = 5;
  pager: any = {};

  ngOnInit() {
    this.pager = paginate(this.items.totalElements, this.initialPage, this.pageSize, this.maxPages)
  }

  ngOnChanges(changes: SimpleChanges) {

   
    // initialize pagination if has not previous value
    if (changes.items && changes.items.firstChange && changes.items.currentValue && changes.items.currentValue.totalPages !== 0) {
     
        // max value for initial page is the number of total pages
        if (this.initialPage > changes.items.currentValue.totalPages) {
          this.initialPage = changes.items.currentValue.totalPages;
          this.onChangeSetPage(this.initialPage);
        }

        // min value for initial page is 1
        if (this.initialPage < 1) {

          this.initialPage = 1;
          this.onChangeSetPage(this.initialPage);
        }
       
    }
    // reset pager if items array has changed
    else if (changes.items && changes.items.previousValue !== changes.items.currentValue) {
      this.pager = paginate(changes.items.currentValue.totalElements, changes.items.currentValue.number + 1, this.pageSize, this.maxPages);

      // if items's content is empty resets currentPage
      // used if updated activation status changed the number of total pages  
 
      if (changes.items.currentValue.empty && changes.items.previousValue && changes.items.previousValue.totalPages !== 0) {
        
        this.onChangeSetPage(this.pager.currentPage)        
      }
    }
  }

  onChangeSetPage(page) {
    // call change page function in parent component
    this.changePage.emit(page);
  }

}
