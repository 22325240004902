import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, } from '@angular/forms';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';
import { MetadataService } from '../../MetadataService';
import { shareReplay, map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/ConstantsService';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-edit-post-job',
  templateUrl: './edit-post-job.component.html',
  styleUrls: ['./edit-post-job.component.css']
})
export class EditPostJobComponent implements OnInit {
  postJobForm: FormGroup;
  metadata$: Observable<any>;
  industries$: Observable<Array<{ id: number; text: string; tags: any }>>;
  employment_types$: Observable<Array<{ id: number; text: string; }>>;
  experience_levels$: Observable<Array<{ id: number; text: string; }>>;
  locations$: Observable<Array<{ id: number; text: string; }>>;
  tags: any;
  selectedIndustry: any;
  submitted = false;
  minDate = undefined;
  jobDetails: any;
  jobUuid;
  initEditor: {};
  initEditorBenefitsAndPrerequisites: {};
  message: string;

    //variables for alert message depends on status
    companyActivationStatus = null;
    alertOptionForActivationStatus = null;


  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private constants: ConstantsService,
    private config: NgbDatepickerConfig,
    private _snackBar: MatSnackBar
  ) {

    this.initEditor = {
      base_url: '/tinymce',
      suffix: '.min',
      entity_encoding: "raw",
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
    };
    this.initEditorBenefitsAndPrerequisites = {
      base_url: '/tinymce',
      suffix: '.min',
      valid_elements: "-ul,-li",
      entity_encoding: "raw",
      setup: function (editor) {
        editor.on("init", function () {
          if (!editor.getContent()) {
            editor.execCommand('InsertUnorderedList');
            window.scroll(0, 0);
          }
        });
        // This forces all 'enter' and 'backspace' keys to create an 'ul li' element
        editor.on('keyup', function (e) {
          if (e.keyCode == 8 || e.keyCode==13) {
            if (editor.editorManager.activeEditor.selection.getNode().nodeName.toLowerCase() != 'li') {
              editor.execCommand('InsertUnorderedList');
            }
          }
        });
        editor.on('keydown',function(e){
          if(e.keyCode == 13){
            if(editor.editorManager.activeEditor.selection.getNode().nodeName.toLowerCase() != 'li'){
              editor.execCommand('InsertUnorderedList');
            }

          }
        });
      },
      height: 300,
      menubar: false,
      advlist_bullet_styles: "disc",
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: false
        
    };

  }

  ngOnInit() {

  this.metadata$ = this.metadataService.getMetaData()
    .pipe(shareReplay(1));

  this.industries$ = this.metadata$.pipe(
    map(metadata => metadata.industries.map(industry => ({ id: industry.id, text: industry.name, tags: industry.tags })))
  );
  this.employment_types$ = this.metadata$.pipe(
    map(metadata => metadata.employment_types.map(employment_type => ({ id: employment_type.id, text: employment_type.name })))
  );
  this.experience_levels$ = this.metadata$.pipe(
    map(metadata => metadata.experience_levels.map(experience_level => ({ id: experience_level.id, text: experience_level.name })))
  );
  this.locations$ = this.metadata$.pipe(
    map(metadata => metadata.locations.map(location => ({ id: location.id, text: location.name })))
  );

    this.getCompanyActivationStatus();
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.minDate = {
      year: tomorrow.getFullYear(),
      month: tomorrow.getMonth() + 1,
      day: tomorrow.getDate()
    };
    
    this.route.params.subscribe((params) => {

      this.jobUuid = params.uuid;

      forkJoin(
        this.authService.getJobDetailsByuuid(this.jobUuid),
        this.industries$
      ).subscribe(([jobDetails,industries])=>{
        
        this.jobDetails=jobDetails;

        this.tags = jobDetails.industry_id 
                          ? industries.find(industry => industry.id === parseInt(jobDetails.industry_id, 10)).tags 
                          : [];
      
        let year: any;
        let month: any;
        let day: any

        if (jobDetails.expiration_date) {
          let expirationDate = jobDetails.expiration_date;

          year = Number(expirationDate.split("-", 1));
          month = Number(expirationDate.split("-", 2)[1]);
          day = Number(expirationDate.split("-", 3)[2]);
        }
        this.postJobForm =
          this.formBuilder.group({
            title: new FormControl(jobDetails.title, [Validators.required, Validators.maxLength(255)]),
            description: new FormControl(jobDetails.description, []),
            prerequisites: new FormControl(jobDetails.prerequisites, []),
            benefits: new FormControl(jobDetails.benefits, []),
            salary: new FormControl(jobDetails.salary, [Validators.pattern(this.constants.POSITIVE_OR_ZERO_REGEX)]),
            job_url: new FormControl(jobDetails.job_url, [Validators.pattern(this.constants.URL_REGEX), Validators.maxLength(255)]),
            email: new FormControl(jobDetails.email, [Validators.required, Validators.email]),
            industry_id: new FormControl(jobDetails.industry_id, []),
            location_id: new FormControl(jobDetails.location_id, [Validators.required]),
            exp_lvl_id: new FormControl(jobDetails.exp_lvl_id, []),
            employment_type_id: new FormControl(jobDetails.employment_type_id, []),
            tags: new FormControl(jobDetails.tags ? jobDetails.tags : [], []),
            expiration_date: new FormControl(jobDetails.expiration_date ? { year: year, month: month, day: day } : null, []),
            status: new FormControl(null, [])
          });
      });
    })

  }

  get formControls() {
    return this.postJobForm.controls;
  }

  postAJob(status) {
    this.submitted = true;
    if (this.postJobForm.invalid) {
      window.scroll(100, 500);
      console.log("invalid post a job form");
      return;
    }

    let expDateJson = this.postJobForm.value.expiration_date;
    if (expDateJson) {
      this.postJobForm.patchValue({
        expiration_date: new Date(expDateJson.year, expDateJson.month - 1 , expDateJson.day)
      });
    }

    if (status === 'published') {
      this.postJobForm.controls['status'].setValue("published");
      this.message = '✓ Η ανάρτηση ενημερώθηκε.';
    }

    this.authService.updateJobPost(this.jobUuid, this.postJobForm.value)
      .subscribe(jobdetails => {

        this.router.navigate(['job-details', jobdetails.uuid]);
        this.openSnackBar();
      });
  }

  selectedIndustryHandler(event: any) {
    //update the ui
    //selectedIndustry holds the selected industry id
    this.selectedIndustry = event;
    this.postJobForm.patchValue({
      tags: []
    });

    this.industries$.subscribe(industries => {
      this.tags = industries.find(industry => industry.id === parseInt(this.selectedIndustry, 10)).tags
    });
  }

  getCompanyActivationStatus(){
    this.authService.getCompanyActivationStatus(this.authService.getCompanyId()).subscribe((res)=>{
        this.companyActivationStatus = res;
        switch(this.companyActivationStatus){
          case 'pending':
            this.alertOptionForActivationStatus = 4;
            break;
          case 'disapproved':
            this.alertOptionForActivationStatus = 5;
            break;
          // case 'approved' none alert message
        }
    })
  }

  // pop up message 
  openSnackBar() {
    this._snackBar.open(this.message, null, {
      duration: 4000,
    });
  }

}
