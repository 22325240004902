import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../MetadataService';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { shareReplay, map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from '../dataService';
import { ConstantsService } from '../ConstantsService';
import { sortBy } from 'sort-by-typescript';
@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.css']
})
export class CandidateProfileComponent implements OnInit {

  candidateData: any;
  experience: any[];
  education: any[];
  seminars: any[];
  candidateLanguages: any[];
  metadata$: Observable<any>;
  avatar: boolean = false;
  uuid: any;
  employment_types$: Observable<Array<{ id: number; name: string; }>>;
  items: any[];
  fileUrl: string;
  cv: File = null;
  cvMessage: string;
  metadataLanguages$: Observable<Array<{ id: number; name: string; }>>;
  prefectures$: Observable<Array<{ id: number; name: string; }>>;
  education_levels$: Observable<Array<{ id: number; text: string }>>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private metadataService: MetadataService,
    private dataService: DataService,
    private constants: ConstantsService
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.uuid = params.uuid ? params.uuid : this.authService.getUUID();

      this.authService.getCandidateProfile(this.uuid).subscribe((data: any) => {

      this.candidateData = data;
      this.experience = this.candidateData.candidate_experience_work.sort(sortBy('-year_start'));
      this.education = this.candidateData.candidate_education.sort(sortBy('-year_start'));
      this.seminars = this.candidateData.candidate_seminars;
      this.candidateLanguages = this.candidateData.candidate_languages;

      if (this.candidateData.image_id) {
        this.avatar = true;
        this.candidateData.image = this.authService.getLogo("candidate", "image", this.candidateData.uuid, new Date().getTime().toString());
      }
      else {
        this.avatar = false;
      }

      //get metadata
      this.metadata$ = this.metadataService.getMetaData()
        .pipe(shareReplay(1));

      this.employment_types$ = this.metadata$.pipe(
        map(
          metadata => metadata.employment_types.map(employment_type => ({ id: employment_type.id, text: employment_type.name }))
        )
      );

      this.metadataLanguages$ = this.metadata$.pipe(
        map(
          metadata => metadata.languages.map(languages => ({ id: languages.id, text: languages.name }))
        )
      );

      this.prefectures$ = this.metadata$.pipe(
        map(metadata => metadata.prefecture.map(prefecture => ({ id: prefecture.id, text: prefecture.name })))
      );

      this.education_levels$ = this.metadata$.pipe(
        map(metadata => metadata.education_levels.map(education_level => ({ id: education_level.id, text: education_level.name })))
      );

      this.getData();
    });
  });
  
  }

  completeProfile() {
    this.router.navigate(['candidates/complete-profile']);
  }

  getData() {

    this.employment_types$.subscribe((employment_types: any[]) => {
      this.experience.forEach(item => {
        let x = employment_types.find(locItem => locItem.id === item.employment_type);

        if (x) {
          item.employment_types_name = x.text;
        }
        else {
          item.employment_types_name = "–";
        }

      });
    });

    this.metadataLanguages$.subscribe((languages: any[]) => {

      this.candidateLanguages.forEach(item => {
        let x = languages.find(locItem => locItem.id === item.language_id);

        if (x) {
          item.language_name = x.text;
        }
        else {
          item.language_name = "";
        }
      });
    });

    this.prefectures$.subscribe((prefectures: any[]) => {
      let x = prefectures.find(prefecture => prefecture.id === this.candidateData.prefecture_id);
      
      if (x) {
        this.candidateData.prefecture_name = x.text;
      }
      else {
        this.candidateData.prefecture_name = "";
      }
      
    });

    this.education_levels$.subscribe((education_levels: any[]) => {
      //  for the education_lvl_id in cv info
      let x = education_levels.find(education_level => education_level.id === this.candidateData.education_lvl_id);
      if (x) {
        this.candidateData.education_lvl_name = x.text;
      }
      else {
        this.candidateData.education_lvl_name = "";
      }
      //  for the education_lvl_id in education form
      this.education.forEach(item => {
        let x = education_levels.find(locItem => locItem.id === item.education_lvl_id);

        if (x) {
          item.education_lvl_name = x.text;
        }
        else {
          item.education_lvl_name = "";
        }
      });
    });
  }

  downLoadCv(candidateUuid) {
    const data = this.authService.downloadFile("candidate", "cv", new Date().getTime().toString(), candidateUuid);
    this.fileUrl = data;
  }

  appliedJobList() {
    this.router.navigate(['candidates/applied-job-list']);
  }

}
