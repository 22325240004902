import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../dataService';
import { MetadataService } from '../MetadataService';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.css']
})
export class CandidatesListComponent implements OnInit {

  candidatesData: any[];
  candidatesSearchForm: FormGroup;
  isFiltered: boolean;
  pageOfItems: Array<any>;
  sortBy: string;
  sorting: string = "asc";
  numberOfCandidates: number;
  education_levels$: Observable<Array<{ id: number | string; text: string; }>>;
  metadata$: Observable<any>;
  educationLevelId: any;
  types: any[];
  yearsOfWorkExperience: any;
  keywords: string;
  filters: any;
  candidates: any;

    // for pagination
    currentPage: any;
    pageSize: number = 9;
    firstPage: number = 1;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metadataService: MetadataService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {

      this.educationLevelId = params.getAll('educationLevelId');
      this.yearsOfWorkExperience = params.get('yearsOfWorkExperience');
      this.keywords = params.get('keywords');
      this.currentPage = params.get('page') ? params.get('page') : 1;
      // sort by firstName or CreationDate
      // default value firstName
      this.sortBy = params.get('sortBy') ? params.get('sortBy') : "firstName";
      // firstName asc sorting
      // creationDate desc sorting
      this.sorting = this.sortBy === "firstName" ? "asc" : "desc";

      if (this.educationLevelId.length > 0 || this.yearsOfWorkExperience || this.keywords) {
        this.isFiltered = true;
      }
      else {
        this.isFiltered = false;
      }

      this.candidatesSearchForm =
        this.formBuilder.group({
          keywords: new FormControl(this.keywords ? this.keywords : null, []),
          educationLevelId: this.formBuilder.array([]),
          sortBy: new FormControl(this.sortBy, []),
          isSearchable: new FormControl(true, []),
          status: new FormControl("approved", []),
          yearsOfExperienceFrom: new FormControl(null, []),
          yearsOfExperienceTo: new FormControl(null, []),
          yearsOfWorkExperience: new FormControl(this.yearsOfWorkExperience ? this.yearsOfWorkExperience : null, [])
        });

      if (this.educationLevelId) {
        const educationLevelId: FormArray = this.candidatesSearchForm.get('educationLevelId') as FormArray;
        this.educationLevelId.forEach(value => {
          educationLevelId.push(new FormControl(value));
        });
      }

      if (this.yearsOfWorkExperience) {
        this.getYearsOfWorkExperience(this.yearsOfWorkExperience, this.currentPage);
      }

      this.initialize(this.sortBy, this.sorting );
    })
  }

  initialize(option, sorting) {

    this.getAllCandidates(option, sorting);
    this.getMetadata();

  }

  getMetadata() {
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.education_levels$ = this.metadata$.pipe(
      map(metadata => metadata.education_levels.map(education_level => ({ id: education_level.id, text: education_level.name })))
    );

    this.education_levels$.subscribe((education_levels: any[]) => {
      this.types = education_levels;
      if (this.educationLevelId) {
        // selected is used in checkbox
        this.types.forEach(type => {
          let x = this.educationLevelId.find(param => param === type.id.toString())
          if (x) {
            type.selected = true;
          }
          else {
            type.selected = false;
          }
        });
      }
    })
  }

  getAllCandidates(option, sorting) {
    // get data for all the candidates
    this.currentPage =this.currentPage - 1; 
    
    this.authService.getCandidatesListWithFiltersAndSorting(option, sorting, this.candidatesSearchForm.value, this.currentPage, this.pageSize).subscribe((candidates: any) => {

      this.candidates = candidates;
      this.candidatesData = candidates.candidateSearchResults.content;
      this.numberOfCandidates = candidates.candidateSearchResults.totalElements;
      
      this.filters = candidates.candidateFilters;
      // get image
      this.candidatesData.forEach(candidate => {
        if (candidate.imageId) {
          candidate.image = this.authService.getLogo("candidate", "image", candidate.uuid, new Date().getTime.toString());
        }
      });
    });
  }

  // for keywords input
  getCandidateFilteredList() {
    this.router.navigate(['/candidates/list'], { queryParams: { keywords: this.candidatesSearchForm.value.keywords, page: 1 }, queryParamsHandling: 'merge' });
  }

  discardfilters() {
    // keeps the sortBy value
    this.router.navigate(['/candidates/list'], { queryParams: { sortBy: this.candidatesSearchForm.value.sortBy, page: 1 } });
  };

  candidateProfile(candidate) {
    this.router.navigate(['candidate/profile', candidate.uuid]);
  }

  // for dropdown
  // options firstName/ creationDate
  onChange(e) {
    // this.sortBy = e.target.value;
    this.router.navigate(['/candidates/list'], { queryParams: { sortBy: this.candidatesSearchForm.value.sortBy, page: 1 }, queryParamsHandling: 'merge' });
  }

  // for education level checkbox filter
  onCheckboxChange(e) {
    const educationLevelId: FormArray = this.candidatesSearchForm.get('educationLevelId') as FormArray;

    if (e.target.checked) {
      educationLevelId.push(new FormControl(e.target.value));
    }
    else {
      let i: number = 0;
      educationLevelId.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          educationLevelId.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.router.navigate(['/candidates/list'], { queryParams: { educationLevelId: this.candidatesSearchForm.value.educationLevelId, page: 1 }, queryParamsHandling: 'merge' });
  }

  //for yearsOfWorkExperience radio filter
  getYearsOfWorkExperience(value, page) {

    this.currentPage = page;
    const yearsOfExperienceFrom: FormControl = this.candidatesSearchForm.get('yearsOfExperienceFrom') as FormControl;
    const yearsOfExperienceTo: FormControl = this.candidatesSearchForm.get('yearsOfExperienceTo') as FormControl;

    if (value === "1") {
      yearsOfExperienceFrom.setValue(0);
      yearsOfExperienceTo.setValue(3);
    }
    else if (value === "2") {
      yearsOfExperienceFrom.setValue(4);
      yearsOfExperienceTo.setValue(6);
    }
    else {
      yearsOfExperienceFrom.setValue(7);
      yearsOfExperienceTo.setValue(60);
    }
    window.scrollTo({
      top: 400,
      behavior: 'smooth',
    });

    this.router.navigate(['/candidates/list'], { queryParams: { yearsOfWorkExperience: this.candidatesSearchForm.value.yearsOfWorkExperience, page: this.currentPage }, queryParamsHandling: 'merge' });
  }

    //for pagination
    onChangePage(currentPage: number) {
      this.currentPage = currentPage;

      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });

      this.router.navigate(['/candidates/list'], { queryParams: { page: this.currentPage }, queryParamsHandling: 'merge' });
    }

}

