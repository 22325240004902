import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { tap, catchError, filter, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';

@Injectable()
export class RefreshTokenInterceptorService implements HttpInterceptor {

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService:AuthService,private router:Router){}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getToken()
    
    if(token){

      return next.handle(request).

              // get time of last successfulRequest
              pipe(tap(event=>{
                if(event instanceof HttpResponse){
                    localStorage.setItem('lastSuccessfulReqTime',new Date().toString());
                }
              }),

              // refresh token if idle time is not longer than 1 hour
              catchError((error)=>{
                  // We don't want to refresh token for refresh token itself
                  // So we verify url and we redirect to login with an error message
                    if (request.url.includes("refreshTokens")) {
                        console.log(error.message + " : error in refreshTokens")
                        if(error.status === 401){
                          this.refreshTokenInProgress = false;

                          if(this.authService.isCompany || this.authService.isCandidate()){
                            this.authService.AfterUnauthorizedRequestActions()
                          }
                          else if(this.authService.isAdminFunc()){
                              this.authService.AfterAdminUnauthorizedRequestActions()
                          }
                          this.authService.AfterUnAuthorizedRequestUiMessage()
                          return throwError(error);
                        }

                    }
                    //if its not an auth error forward the request and let the error be handled for every specific request
                    if(error.status !== 401){
                      return throwError(error);
                    }
 
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    if(this.refreshTokenInProgress){
                      console.log(this.refreshTokenInProgress + ':refresh Token Progress')
                      return this.refreshTokenSubject.pipe(
                        filter(token => token!==null),
                        take(1),
                        switchMap(token =>{ 
                          console.log("token came to me")
                            return next.handle(this.authService.addAuthHeadersToRequest(request,token))
                          })
                      );
                    }
                    else{
                      //  in Progress is true
                          console.log("req for refresh token");
                          this.refreshTokenInProgress = true;
                          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                          this.refreshTokenSubject.next(null);

                          return this.authService.refreshToken().pipe(
                            switchMap((res)=>{
                              this.refreshTokenInProgress = false;
                              this.refreshTokenSubject.next(res.accessToken);
             
                              return next.handle(this.authService.addAuthHeadersToRequest(request,this.authService.getToken()));
                          }),
                          catchError((error)=>{
                            this.refreshTokenInProgress = false;
                            if(error.status === 400){
                              console.log("forward the req")
                              return next.handle(this.authService.addAuthHeadersToRequest(request,this.authService.getToken()));
                            }
                            else if(error.status === 401){
                              this.refreshTokenInProgress = false;

                              if(this.authService.isCompany || this.authService.isCandidate()){
                                this.authService.AfterUnauthorizedRequestActions()
                              }
                              else if(this.authService.isAdminFunc()){
                                  this.authService.AfterAdminUnauthorizedRequestActions()
                              }
                              this.authService.AfterUnAuthorizedRequestUiMessage()
                              return throwError(error);
                            }
                          }))
                          
                    }
                    
              }))
    }
    return next.handle(request);
  }
}
