import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { RouterLink, Router } from '@angular/router';
import { MetadataService } from '../MetadataService';
import { AllJobsService } from '../AllJobsSevice';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  signin: boolean;
  signup: boolean;
  activeTab: number;
  loginForm: FormGroup;
  signupCandidateForm: FormGroup
  isSubmitted = false;
  signupEmployerForm: FormGroup;
  searchJobByLocationKeywords: FormGroup;
  message: any;
  hide: boolean;
  employermessage: any;
  errormessage: boolean;
  metadata: any;
  jobsCount: number;
  metadata$: Observable<any>;
  locations$: Observable<any>;
  epimelitiriou: string;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private allJobsService: AllJobsService
  ) { }

  ngAfterViewInit() {    
    // import('../../assets/js/script.js');
  }

  ngOnInit() {
    this.hide = true;
    this.signin = false;
    this.signup = false;
    this.message = "";
    this.employermessage = "";
    this.errormessage = false;
    this.epimelitiriou = environment.chamber2;

    // get metadata
    this.metadata$ = this.metadataService.getMetaData()
    .pipe(shareReplay(1));
    
    this.locations$ = this.metadata$.pipe(
      map(
        metadata =>
        [{ id: -1, text: "Ολες οι Περιοχές" }].concat(metadata.locations.map(location => ({ id: location.id, text: location.name }))
        )
    )

  );


    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.signupCandidateForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.signupEmployerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      company: ['', Validators.required]
    });

    this.allJobsService.getJobsCount().subscribe(number => {
      this.jobsCount = number;
    })

    this.searchJobByLocationKeywords =
      this.formBuilder.group({
        locationId: new FormControl('', []),
        keyword: new FormControl('', []),
      });
    const locationId = this.searchJobByLocationKeywords.get("locationId")
    locationId.valueChanges.subscribe(val => {
     
      if (val == -1) {
        locationId.setValue("");
      }
    })

  }

  get formControls() {
    return this.loginForm.controls;
  }
  get formControls2() {
    return this.signupEmployerForm.controls;
  }
  get formControls3() {
    return this.signupCandidateForm.controls;
  }
  


  // login() {
  //   //console.log(this.loginForm.value);
  //   this.isSubmitted = true;
  //   if (this.loginForm.invalid) {
  //     return;
  //   }

  //   if (this.activeTab == 0) {
  //     this.authService.loginCandidate(this.loginForm.value)
  //       .subscribe(
  //         user => {
  //           // store user details and jwt token in local storage to keep user logged in between page refreshes
  //           localStorage.setItem('ACCESS_TOKEN', JSON.stringify(user));

  //           //get metadata 
  //           this.metadataService.getMetaData().subscribe((data: any) => {
  //             console.log(data)
  //             this.metadata = data;
  //           });
  //           if (this.activeTab == 0) {
  //             this.router.navigate(['candidates'])
  //           }
  //           else {
  //             this.router.navigate(['employers'])
  //           }
  //         }, error => {
  //           //backend error message 
  //           this.message = error.error.message;
  //         }
  //       );
  //   }
  //   else {
  //     this.authService.loginEmployer(this.loginForm.value)
  //       .subscribe(user => {
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes
  //         localStorage.setItem('ACCESS_TOKEN', JSON.stringify(user));

  //         this.router.navigate(['employers'])

  //       },
  //         error => {
  //           console.log(error.error.message);
  //           this.message = error.error.message;
  //         });

  //   }
  //   if (this.loginForm) {
  //     this.loginForm.reset();
  //     this.loginForm.markAsUntouched();
  //   }
  //   //this.router.navigateByUrl('/employers/profile');
  // }

  signUpCandidate() {
    if (this.signupCandidateForm.invalid || this.signupCandidateForm.hasError('required') || this.signupCandidateForm.hasError('email')) {
      this.errormessage = true;
      return;
    }
    this.authService.signupCandidate(this.signupCandidateForm.value)
      .subscribe(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.router.navigate(['candidates/complete-profile']);
      },
        error => {
          console.log(error.error.message);
          this.message = error.error.message;
        });
    if (this.signupCandidateForm) {
      this.signupCandidateForm.reset();
      this.signupCandidateForm.markAsUntouched();
    }
  }

  // signupEmployer() {
  //   if (this.signupEmployerForm.invalid || this.signupEmployerForm.hasError('required')) {
  //     console.log("test")
  //     this.errormessage = true;
  //     return;
  //   }

  //   this.authService.signupEmployer(this.signupEmployerForm.value)
  //     .subscribe(user => {
  //       // store user details and jwt token in local storage to keep user logged in between page refreshes
  //        this.router.navigate(['employers/complete-profile']);

  //     },
  //       error => {
  //         console.log(error.error.message);
  //         this.message = "test";
  //         this.employermessage = error.error.message;
  //       });
  //   if (this.signupEmployerForm) {
  //     this.signupEmployerForm.reset();
  //     this.signupEmployerForm.markAsUntouched();
  //   }
  // }


  // 0 for Candidate(Find a job) button 
  setCandidateActive() {
    this.activeTab = 0;
  }

  // 1 for Employer(Post a job) button 
  setEmployersActive() {
    this.activeTab = 1;
  }

  //shows sign in form
  clickSignin() {
    this.signin = true;
  }

  //shows home page
  cancel() {
    this.signin = false;
    this.message = "";
    if (this.loginForm) {
      this.loginForm.reset();
      this.loginForm.markAsUntouched();
    }
  }

  //shows sign up form
  register() {
    this.message = "";
    this.signup = true;
    this.signin = false;
  }

  //shows sign in form
  cancelSignUp() {
    this.message = "";
    this.signin = true;
    this.signup = false;
    this.errormessage = false;
    if (this.signupCandidateForm) {
      this.signupCandidateForm.reset();
      this.signupCandidateForm.markAsUntouched();
    }
    if (this.signupEmployerForm) {
      this.signupEmployerForm.reset();
      this.signupEmployerForm.markAsUntouched();
    }
    if (this.loginForm) {
      this.loginForm.reset();
      this.loginForm.markAsUntouched();
    }
  }

  //rout job listing page with data search fields
  getJobListByLocationKeywords() {
    if (this.searchJobByLocationKeywords.invalid) {
      console.log("invalid post a job form");
      return;
    }
  
    this.router.navigate(['/job-listing'], { queryParams: { locationId: this.searchJobByLocationKeywords.get("locationId").value, keywords: this.searchJobByLocationKeywords.get("keyword").value } });
 
  }

}
