import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { sortBy } from 'sort-by-typescript';

@Component({
  selector: 'app-applied-candidates-list',
  templateUrl: './applied-candidates-list.component.html',
  styleUrls: ['./applied-candidates-list.component.css']
})
export class AppliedCandidatesListComponent implements OnInit {

  jobPostUuid: any;
  companyUuid: any;
  content: any;
  applications: any;
  fileUrl: string;
  currentApplication: any;
  sorting: string = "desc";
  fullname: string;
  title: string;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      this.jobPostUuid = params.uuid;
      this.companyUuid = params.company_uuid;

      this.authService.getJobDetailsByuuid(this.jobPostUuid).subscribe((data) => {
        this.title = data.title;
      });

      this.authService.getAppliedCandidates(this.companyUuid, this.jobPostUuid, this.sorting).subscribe((data) => {
        this.applications = data
        this.content = data.content;
      });
    });
  }

  candidateProfile(uuid) {
    this.router.navigate(['candidate/profile', uuid]);
  }

  jobDetails() {
    this.router.navigate(['job-details', this.jobPostUuid]);
  }

  openModal(applicationData) {
    this.currentApplication = applicationData;
    $('#applyModal').modal('show');
  }

  downloadCV(data) {
    const cv = this.authService.downloadCVOfJobApplicationForCompany(data.candidateJobPostInfo.jobPostUuid, data.candidateJobPostInfo.candidateUuid);
    this.fileUrl = cv;
  }

  // for sorting: asc or desc
  onChange(e) {
    this.sorting = e.target.value === "desc" ? "desc" : "asc";

    if (this.sorting == "desc") {
      this.content = this.content.sort(sortBy('-candidateJobPostInfo.creationDate'));
    }
    else {
      this.content = this.content.sort(sortBy('candidateJobPostInfo.creationDate'));
    }
    // this.authService.getAppliedCandidates(this.companyUuid, this.jobPostUuid, this.sorting).subscribe((data) => {

    //   this.applications = data
    //   this.content = data.content;
    // });
  }
}
