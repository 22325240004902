import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { take, map, catchError } from 'rxjs/operators';


@Injectable()
export class AdministratorCvHandlingResolver implements Resolve<any> {
    
    adminUuid:any;
    constructor(
        private authService: AuthService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {

        this.adminUuid = route.params.uuid ? route.params.uuid : this.authService.getAdminUUid();
    
        return this.authService.getAdminProfile(this.adminUuid).pipe(     
            take(1),
            catchError(error => {
                return throwError(error);
            }),
            map((resp: any) => {
                if (resp) {
                    return resp;
                } else {
                    return [];
                }
            })
        );
    }
}