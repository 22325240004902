import { Component, OnInit } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { DataService } from '../dataService';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.css']
})
export class SignUpModalComponent implements OnInit {

  constructor(
    private socialAuthService: AuthService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
  }

}
