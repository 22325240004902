import { Component, OnInit,Input } from '@angular/core';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-message-for-login-or-register-modal',
  templateUrl: './message-for-login-or-register-modal.component.html',
  styleUrls: ['./message-for-login-or-register-modal.component.css']
})
export class MessageForLoginOrRegisterModalComponent implements OnInit {
  private showLogin = false;
  private showSignUp = false;
  constructor(
    private messageService:MessageService
  ) { }

  ngOnInit() {

  	$('#MessageModal').on('hidden.bs.modal',() =>this.pickShow(this.showLogin,this.showSignUp));
  }

  login(){
  	this.showLogin = true;
  	$('#MessageModal').modal('hide');

  }
  
  register(){
  	this.showSignUp = true;
  	$('#MessageModal').modal('hide');
  }

  pickShow(showLogin,showSignUp){
  		if(showLogin){
  			$('#LoginModal').modal('show');
  			this.showLogin = false;	
  		}
  		else if(showSignUp){
  			$('#SignUpModal').modal('show');
  			this.showSignUp = false;
  		}
  		else{
  			console.log("notification modal just closed");
  		}
  }

}
